
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');



.LandingPage{
  background-image:url('../images/dark_bg.svg');  
  background-repeat: no-repeat;
  background-attachment:fixed;
  background-color: #161618;
  background-size: cover;
  font-family: 'Roboto', sans-serif;
  .navbar-nav{
    .nav-link{
      font-size:18px;
      color: #fff;
      margin: 0 20px;
      &:hover{
        color:$primary;
      }
    }
  }
  .item{
    hr{
      border-width:2px;
      border-color: #fff;
    }
  }
  .video-section{
    img {
      box-shadow: 0px 0px 80px #666 !important;
      width: 100%;
      border-radius: 2%;
    }
  }
  .device_right{  
    
    p{
      font-size:18px ;
    }
  }

  .land_app_section{
    background-image: url(../images/app_bg.png);
    background-repeat: no-repeat;
    background-position: center;    
  }
  .platform_section {    
    .left,.right{
      h4{
        font-size: 30px;
      }
      .des{
        p{
          font-size: 21px;
        }
      }
    }
  }

  .section_one{
    padding: 50px 0px;
    .title{
      font-size: 4.5rem;      
      line-height: 92px;
      font-weight: 700;
    }
    .description{
      font-size: 20px;
    }
  }
  .section_two svg {
      width: 100%;
  }
  .img-fluid{
    svg{
      width: 400px;
      @media (max-width: 767.98px) {
        width: 100%;        
       }       
    }
  }
  .land_app_section {    
    .btn-link{
      text-decoration: none;
    }
  }
  .navbar-nav{
    .btn-link{
      text-decoration: none;
    }
  }
  .bg-dark{
    background-color: #161618 !important;
  }

  .section_three{
    .suit_item{
      .card {
        .card-body {
          .ribbon {
            .wrap {
              // .ribbon6{
              //   height: 18px !important;
              //   line-height: 18px;
              //   left: -40px !important;
              //   font-size: 10px !important;
              //   top: 15px !important;
              // }
            }
          }
        }
      }
    }
  }
  

  .suites {
    .suite_list {
      .suit_item{
        padding-top: 9px;
        .card {
          .card-body {
            // .ribbon {
            //   .wrap{
            //     width: 90px;
            //     height: 90px;
            //     &::before{
            //       width: 30px;
            //     }
            //     &:after{
            //       height: 30px;
            //       border-radius: 0px 8px 8px 26px;
            //     }
            //   }
            // }
            .desc-text{
              text-align: left;
              min-height: 42px;
            }
          }
        }
        &.appcard{
          .card{
            .card-body{
              .card-title{
                font-size: 19px;
              }             
              
            }
          }
        }
      }
      
    }
  } 
    
  

}