.suites{
  .suite_list{
    .suit_item{      
      .card{
        width: 250px;
        height: 341px;
        .card-body{
          .device-img{
            width: 147px;
            height: 147px;
            object-fit: cover;
            margin-top: -40px;
            
            .card-img-top{
              object-fit: cover;
              width: 147px;
              height: 147px;
              border: 2px solid #fff;
              cursor:pointer;
            }
          }
          .card-title{
            font-size: 20px;
            text-decoration: none;  
            cursor: pointer;
            text-align: left;          
          }
          .desc-text{
            min-height: 42px;
          }
          .card-text{
            font-size: 14px;
            color: #979C9E;
          }
          .list-group{
            .list-group-item{
              font-size: 15px;
              color: #979C9E;
              line-height: 17px;
            }
          }
          .vertical-icon{
            .dropdown{
              button{
                &::after{
                  display: none;
                }
              }
            }
          }
          .ribbon {          
            color: white;
            .wrap {
              width: 100%;
              height: 188px;
              position: absolute;
              top: -8px;
              left: -8px;
              overflow: hidden;
              &:before {
                width: 40px;
                height: 8px;
                left: 54px;
                background: #4D6530;
                border-radius: 8px 8px 0px 0px;
                content: "";
                position: absolute;
              }
              &:after {
                width: 8px;
                height: 40px;
                left: 0px;
                top: 54px;
                background: #4D6530;
                border-radius: 0px 8px 8px 0px;
                content: "";
                position: absolute;
              }
              .ribbon6 {
                width: 150px;
                height:27px;
                line-height:24px;
                position: absolute;
                top: 19px;
                left: -45px;
                z-index: 1;
                overflow: hidden;
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                border: 1px dashed;
                box-shadow:0 0 0 3px $primary,  0px 21px 5px -18px rgba(0,0,0,0.6);
                background: $primary;
                text-align: center;
                &.public{
                  box-shadow:0 0 0 3px $primary,  0px 21px 5px -18px rgba(0,0,0,0.6);
                  background: $primary;
                }
                &.private{
                  box-shadow:0 0 0 3px $secondary,  0px 21px 5px -18px rgba(0,0,0,0.6);
                  background: $secondary;
                }
                &.org{
                  box-shadow:0 0 0 3px $warning,  0px 21px 5px -18px rgba(0,0,0,0.6);
                  background: $warning;
                }
              }
            }
          }
          .vertical-icon{
            margin-top: -20px;
            margin-right: -12px;
            .heart{
              padding: 0px;
              padding-right: 5px;
              padding-top: 2px;
              svg{
                color: #979C9E;
              }
            }
            .dropdown{
              .dropdown-toggle{
                margin-top: 5px;
                margin-left: 2px;
              }
            }
          }
          .rating{
            line-height: 14px;
          }
        }
        .tag {
          margin-top: -10px;
          // margin-left: -14px;
          width: 35%;
          display: block;
          padding: 4px 10px;
          position: absolute;
          left: 0px;
          top: 20px;
            &.public{
              background-color: #48A7F8;
            }
            &.private{
              background-color: #000000;
            }
            &.org{
              background-color: #FFB323;
            }
          color: white;
          // border-top-left-radius: 7px;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        
          // &::after {
          //   display: block;
          //   content: "";
          //   height: 14px;
          //   width: 14px;
          //   position: absolute;
          //   // &.public{
          //   //   background-color: #48A7F8;
          //   // }
          //   // &.private{
          //   //   background-color: #000000;
          //   // }
          //   // &.org{
          //   //   background-color: #FFB323;
          //   // }
          //   background-color: #979C9E;
          //   left: 0;
          //   bottom: -14px;
          //   border-bottom-left-radius: 14px;
          // }
        }
      }
      &.appcard{
        width: 300px;
        padding-top: 80px;
        .card{
          height: auto;
          width: 260px;
          .card-body{
            .device-img{
              margin-top: -124px;
              width: 120px;
              height: 120px;
              .card-img-top{
                width: 120px;
                height: 120px;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
                border: 2px solid #fff;
              }
              .verified{
                svg{
                  width: 37px;
                }
              }
            }
            .ribbon{
              .wrap{
                left: 0px;
                top: 0px;
                &::after,&::before{
                  display: none;
                }
                .ribbon6{
                  border: 0px none;
                  top: 10px;
                  left: -26px;
                  font-size: 12px;
                  width: 115px;
                }
              }
            }
            .vertical-icon{
              .dropdown{
                .heart{
                  color: #72777A;
                }
              }
            }
          }
        }
      }
    }    
    
  }
  .add-btn {
    .add-device-btn{
      &:hover{        
        svg{
          color: #fff;
        }
      }
    }
  }
}

.create_suite{
  .suite_details{       
    .add-variable{
      .btn-outline-primary{
        &:hover{
          color: #fff;
        }
      }
      .input-group{
        .input-group-text{
          background-color: #0C90E1 !important;
        }
      }
    }
    .nav-tabs{
      .nav-item{
        .nav-link{
          background-color: #F9F9F9;
          color: #6C7072;
          &.active{
            background-color: #fff;
            border-top: 2px solid $primary;
          }
        }
      }
    }
    .suite_details_form{
      input,textarea{
        &::placeholder {
          color: $placeholder-text-color;
        }
      }
    }
    .app_list{
      button{
        margin-bottom: 20px;
      }
      
    }  
  }
}


// custom upload 

.custom_upload{
  width: 200px;
  height: 200px;
  background: #eee;
  border-radius: 50%;
  margin: 0 auto;
  
  input{
    bottom: -9px !important;
    right: 17px;
    position: absolute;
    font-size: 27px;
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 35px; 
    z-index: 1;
    opacity: 0;       
    &::before{
      content: "";
    }
  }
  span{
    bottom: 7px;
    right: 17px;
    position: absolute;
    font-size: 27px;
    background: $primary;
    color: #fff;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    text-align: center;
    line-height: 35px;
    border: 2px solid #fff;
  }
  img{
   width: 200px;
   height: 200px;
   object-fit: cover;
  }
} 

.custom_upload_app{
  width: 175px;
  height: 175px;
  background: #eee;
  border-radius: 50%;
  margin: 0 auto;
  
  input{
    bottom: -9px !important;
    right: 17px;
    position: absolute;
    font-size: 27px;
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 35px; 
    z-index: 1;
    opacity: 0;       
    &::before{
      content: "";
    }
  }
  span{
    bottom: 7px;
    right: 17px;
    position: absolute;
    font-size: 27px;
    background: $primary;
    color: #fff;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    text-align: center;
    line-height: 35px;
    border: 2px solid #fff;
  }
  img{
   width: 175px;
   height: 175px;
   object-fit: cover;
  }
}


.custom_tabs{
  .card{          
    border: 3px solid #F9F9F9 !important;
    &.active,&:hover{
      border: 1px solid $primary !important;
    }
    width: 158px;
    height: 182px;         
      
    .img-box{
      img{
        width: 100px;
        height: 100px;
        object-fit: cover;
      }
      .verified{
        right: 30px !important;
        bottom: 10px !important;
        svg{
          width: 25px;
          height: 25px;
        }              
      }
    }
    .info{
      span{
        font-size: 10px;
      }
    }
    .ribbon {
      height: 188px;            
      background-size: cover;
      text-transform: uppercase;
      color: white;
      .wrap {
        width: 100%;
        height: 188px;
        position: absolute;
        top: -8px;
        left: 8px;
        overflow: hidden;
        &:before {
          width: 40px;
          height: 8px;
          right: 54px;
          background: #4D6530;
          border-radius: 8px 8px 0px 0px;
          content: "";
          position: absolute;
        }
        &:after {
          width: 8px;
          height: 40px;
          right: 0px;
          top: 54px;
          background: #4D6530;
          border-radius: 0px 8px 8px 0px;
          content: "";
          position: absolute;
        }
        .ribbon6 {
          width: 150px;
          height:27px;
          line-height:24px;
          position: absolute;
          top: 19px;
          right: -45px;
          z-index: 1;
          overflow: hidden;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          border: 1px dashed;
          box-shadow:0 0 0 3px $primary,  0px 21px 5px -18px rgba(0,0,0,0.6);
          background: $primary;
          text-align: center;
          &.public{
            box-shadow:0 0 0 3px $primary,  0px 21px 5px -18px rgba(0,0,0,0.6);
            background: $primary;
          }
          &.private{
            box-shadow:0 0 0 3px $secondary,  0px 21px 5px -18px rgba(0,0,0,0.6);
            background: $secondary;
          }
          &.org{
            box-shadow:0 0 0 3px $warning,  0px 21px 5px -18px rgba(0,0,0,0.6);
            background: $warning;
          }
        }
      }
    }
    .tag {
      margin-top: -20px;
      // margin-left: -14px;
      font-size: 13px;
      width: 40%;
      display: flex;
      padding: 2px 8px;
      position: absolute;
      left: 0px;
      top: 20px;
        &.public{
          background-color: #48A7F8;
        }
        &.private{
          background-color: #000000;
        }
        &.org{
          background-color: #FFB323;
        }
      color: white;
      // border-top-left-radius: 7px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    }
  }
  .upload{
    color: #72777A;
    .choose{
      .form-control{
        max-width: 300px;
      }      
    }
  }
}

// for suite details page

.suite_details{
  .device{
    .action-btn{
      .btn{
        border-radius: 25px;
        &:hover{
          color: #fff;
        }
      }
    }
  }
  .slick-list {    
    .slick-slide {
      text-align:-webkit-center;     
      padding: 0px 20px;
      img{
        border: 5px solid #E2E4E5;
      }
    }    
  }
  
  
}
.slick-initialized {
  .slick-slide{
    text-align: -webkit-center;
  }
}

.eidit_suite_modal{
  .modal-body{
    .btn{
      &:hover{
        color: #fff !important;
      }
    }
  }
}

.app_table{
  .table-striped > tbody > tr:nth-of-type(odd) > *{
    background-color: #F7F9FA !important;
  }
}

// for suite details page