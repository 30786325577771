// @import '~bootstrap/scss/bootstrap';


$primary: #0C90E1;
$secondary:#202325;
$green:#23C16B;
$red:#FF5247;
$placeholder-text-color: #B9BABB;
// $text-dark:#202325;
// $offwhite:rgba(255, 255, 255, 0.9);
// $dark-bg:#202325;
// $gray-lightest:#F7F9FA;
// $gray-lighter:#F5F5F5;
// $gray-light:#E3E5E5;


$theme-colors: (
  "primary": $primary,
  "secondary":$secondary,
  "success":$green,
  "warning":#FFB323,
  "danger":$red,
  "light":#F2F7FB,
  "dark": #202325,
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";