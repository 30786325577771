body {
  &.active-nav {
    .side-navbar {
      margin-left: 0;
      width: 296px;

      .logo {
        &.only-desktop {
          display: block !important;
        }

        &.only-devices {
          display: none;
        }
        //display: block;
      }

      .toggle {
        rotate: 0deg;
      }

      .nav {
        li {
          .nav-link {
            &.active {
              background-color: #F2F4F5;
            }
          }

          width: auto !important;

          span {
            display: block !important;
            margin-left: 15px;
          }
        }

        .Settings_title {
          display: block;
        }
      }

      footer {
        &.only-desktop {
          display: block !important;
          height: 54px;
        }

        &.only-devices {
          display: none;
        }
      }
    }

    .my-container {
      transition: 0.4s;
      margin-left: 296px;
    }


  }

  .side-navbar {
    width: 73px;
    height: 100%;
    position: fixed;
    margin-left: 0px;
    transition: 0.5s;
    overflow: hidden;
    z-index: 1;

    .toggle {
      rotate: 180deg;
    }

    .logo {
      &.only-desktop {
        display: none !important;
      }

      &.only-devices {
        display: block;
      }
      //display: block;
    }

    .nav {
      li {
        margin-bottom: 10px;
        width: 46px;

        .nav-link {
          font-size: 18px;
          padding-left: 12px;
          font-family: 'Lato';

          &.active {
            background-color: $light;
          }
        }

        span {
          display: none;
        }
      }

      .Settings_title {
        display: none;
      }
    }

    footer {
      &.only-desktop {
        display: none !important;
      }

      &.only-devices {
        display: block;
        height: 54px;
      }
    }
  }

  .my-container {
    transition: 0.4s;
  }
}