.active-nav{
  .my-container{
    .navbar{
      .toggle{
        rotate: 0deg;
      }
      .nav{
        li{
          span{
            right: 0;
            top: 0;
            height: 20px;
            width: 20px;
            line-height: 11px;
            text-align: center;
          }
        }
      }  
    }
  }
}
.my-container{
  .navbar{
    .navbar-nav{
      .nav-link{
        position: relative;
        span{
          top: 0px;
          right: 0px;
          width: 20px;
          height: 20px;
        }
      }
    }
    .dropdown{
      #dropdown-basic {
        border-radius: 50%;
        height: 40px;
        width: 40px;
        &.dropdown-toggle{
          border: 1px solid $primary;
          background-color: $light;
          &::after{
            position: absolute;
            right: 0;
            top: 16px;
          }
        }
      }
      
      
    }
    .toggle{
      rotate: 180deg;
    }       
    
  }
}