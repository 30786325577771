.device_card_section{
  .device_card {
    // margin-bottom: 30px;

    .card{
        transition: all 0.6s ease;
        max-width: 300px;
        height:430px;                
        @media (max-width: 767.98px) {
          width: 100%;
          height: 100%;
         }       
    
      .card-body{
        .device-img {
          width: 120px;
          height: 120px;
          border-radius: 50%;          
          border: 1px solid #eee;
          img{            
            object-fit: cover;
            height: 100%;
            width: 100%;
            border-radius: 100%;
          }          
      }
        .card-title{
          font-size: 20px;
          text-decoration: none;
          margin-top: 6px;
          display: block;
          font-weight: 600;
        }
        .device_time,.location,.card-text{
          color:#8E8A8A;
          font-size: 16px;         
        }
        .card-text{
          min-height: 45px;
        } 
        .devider {
          border-top: 1px solid #F2F7FB !important;
          opacity: 1;
        }
        .status{
          left: 10px !important;
          width: 20px;
          height: 20px;
          &.on {
            background:$green !important;
          }
        }
        .vertical-icon{
          .dropdown{
            .dropdown-toggle{
              &::after{
                display: none;
              }
            }
          }
        }
        .device-img{
          .verified{
            bottom: -18px !important;
            right: -30px !important;
          }
        }
        .half_circle_progressbar {
          .progress {
            position: relative;
            margin: 6px;
            float: left;
            text-align: center;
            background: no-repeat;
            height: auto;
            overflow: visible;
            display: block;
            span{
              font-size: 17px;
            }
            .barOverflow{
              position: relative;
              overflow: hidden; /* Comment this line to understand the trick */
              width: 80px; height: 40px; /* Half circle (overflow) */
              margin-bottom: -24px; /* bring the numbers up */
              @media (max-width: 767.98px) {
                width: auto;
                height: 15px;
               }      
              .bar{
                position: absolute;
                top: 0; left: 0;
                width: 80px; height: 80px; /* full circle! */
                border-radius: 50%;
                box-sizing: border-box;
                border: 6px solid #eee;     /* half gray, */
                border-bottom-color: #0bf;  /* half azure */
                border-right-color: #0bf;
                @media (max-width: 767.98px) {
                  display: none;
                }      
              }
              &.warning{
                .bar{
                  border-bottom-color:$warning;
                  border-right-color: $warning;
                }
              }
              &.success{
                .bar{
                  border-bottom-color:$success;
                  border-right-color: $success;
                }
              }
              &.danger{
                .bar{
                  border-bottom-color:$danger;
                  border-right-color: $danger;
                }
              }
            }
            .pro-title {
              margin-top: 3px;
            }
          }
        }
      }
    }
  }
}


// for device details page

.device-details{
    .device-img{
      overflow: hidden;
      object-fit: cover;
      border-radius:20px ;      
      width:auto;
      max-height: 300px;
      height: 100%;
      svg{
        width: 100%;
        height: 100%;
      }
      //~csb      
      .img-device{
        height: 100%;
        width:-webkit-fill-available;
        object-fit:'cover';
      }
    }
    .product-info{
      .title{
        h3{
          font-size: 24px;
        }
        .virifyied-icon{
          margin-right: 10px;
          svg{
            width: 23px;
            margin-top: -6px;
          }
        }
      }
        span{
          &.online{
            height: 25px;
            padding: 5px 14px;
            background-color:#4CD471 !important;
          }
          &.offline{
            height: 25px;
            padding: 5px 14px;
            background-color:#909090 !important;
          }
        }
      }
    
      .action-btn{
        button{
          border-radius: 25px;
          font-size: 16px;
          line-height: 22px;
          svg{
            font-size: 18px;
          }
          &:hover{
            color:#fff ;
          }
        }
      }
    
      .progressbar{
        .device-item{
          background-color: #E3E5E5;
          border-radius: 10px;
          margin-bottom: 10px;
          .title{
            width: 115px;
            border-radius: 10px 0 0 10px;
            font-size: 12px;
            padding: 12px;
            svg{
              width: 17px;              
              margin-right: 5px;
            }
          }
          .progress{
            width: 300px;
            background-color: #fff;
            height: 7px;
            .progress-bar{
              height: 7px;
              background-color: $primary;
              height: 7px;
            }
          }
        }
      }

      .suits{
        .card{
          max-height: 189px;
          .card-header{
            background-color: #F2F4F5;
            max-height: 42px;
          }
          .card-body{
            min-height: 150px;
            .rounded-circle{
              object-fit: cover;
            }
          }          
        }
        .running{
          .disabled{
            color: #979C9E !important;
          }          
        }
        
      }
      .information{
        .list-group{
          .list-group-item{
            strong,span{
              color: #72777A;
            }
          }
        }
      }
    
  .information{
    .list-group{
      .list-group-item{
        line-height: 30px;
        strong{
          width: 150px;
        }
      }
    }
  }
}


// step progressbar

.mb-progressbar {
  .mb-progressbar-step {
    padding: 0;
    position: relative;
    .mb-progressbar-step-number {
      color: #979C9E;
      font-size: 12px;
      margin-bottom: 5px;
    }
    .mb-progressbar-dot {
      position: absolute;
      width: 30px;
      height: 30px;
      display: block;
      background: $primary;
      top: 2px;
      left: 50%;
      margin-top: -15px;
      margin-left: -15px;
      border-radius: 50%;
      &:after {
        content: ' ';
        width: 14px;
        height: 14px;
        background: #fff;
        border-radius: 50px;
        position: absolute;
        top: 8px;
        left: 8px;
      }
    }
    .progress {
      position: relative;
      border-radius: 0px;
      height: 8px;
      box-shadow: none;
      margin: 20px 0;
      .progress-bar {
        width: 0px;
        box-shadow: none;
        background: $primary;
      }
    }
    &.complete {
      .progress {
        .progress-bar {
          width: 50%;
        }
      }
    }
    &.active {
      .progress {
        .progress-bar {
          width: 50%;
        }
      }
    }
    &:first-child{
      .progress {
        left: 50%;
        width: 100%;
      }
      &.active{
        .progress{
          .progress-bar{
            width: 0%;
          }
        }
      }
    }
    &:last-child{
      .progress {        
        width: 50%;
      }
      &.active{
        .progress{
          .progress-bar{
            width: 100%;
          }
        }
      }
    }
    &.disabled {
      .mb-progressbar-dot {
        background-color: #f5f5f5;
        pointer-events: none;
        &::after{
          opacity: 0;
        }
      }
    }
    &:last-child{
      &.complete {
        .progress {
          .progress-bar {
            width: 100%;
        }
        }
      }
    }
    &.step3{
      &.complete {
        .progress {
          .progress-bar {
            width: 100%;
        }
        }
      }
    }
  }
}


.connected_tab{
  .nav-link{
    color: $secondary;    
    font-size: 16px;
    font-weight: normal;
    &.active{
      color: $primary;
      font-weight: normal;
    }
  }
  .table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
    background-color: #F7F9FA;
 }
}

.select_suit_modal{
  
  .modal-lg{
    max-width: 654px;
    .modal-content{
      .modal-body{
        .suit_item{
          &::-webkit-scrollbar {
            width: 5px;
          }
          
          &::-webkit-scrollbar-track {
            background-color: #D9D9D9;
            -webkit-border-radius: 10px;
            border-radius: 10px;
          }
          
          &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 10px;
            border-radius: 10px;
            background: #6d6d6d;
          }
          max-height:200px;
          overflow-x: hidden; 
        .focus-ring{
          &:focus{
            border: 1px solid $primary !important;
            box-shadow: none !important;
          }     
          .card{          
            border: 1px solid #F9F9F9 !important;
            &.active,&:hover{
              border: 1px solid $primary !important;
            }
            width: 158px;
            height: 182px;         
              
            .img-box{
              img{
                width: 100px;
                height: 100px;
                object-fit: cover;
              }
              .verified{
                right: 30px !important;
                bottom: 10px !important;
                svg{
                  width: 25px;
                  height: 25px;
                }              
              }
            }
            .info{
              span{
                font-size: 10px;
              }
            }
            .ribbon {
              height: 188px;            
              background-size: cover;
              text-transform: uppercase;
              color: white;
              .wrap {
                width: 100%;
                height: 188px;
                position: absolute;
                top: -8px;
                left: 8px;
                overflow: hidden;
                &:before {
                  width: 40px;
                  height: 8px;
                  right: 54px;
                  background: #4D6530;
                  border-radius: 8px 8px 0px 0px;
                  content: "";
                  position: absolute;
                }
                &:after {
                  width: 8px;
                  height: 40px;
                  right: 0px;
                  top: 54px;
                  background: #4D6530;
                  border-radius: 0px 8px 8px 0px;
                  content: "";
                  position: absolute;
                }
                // .ribbon6 {
                //   width: 150px;
                //   height:27px;
                //   line-height:24px;
                //   position: absolute;
                //   top: 19px;
                //   right: -45px;
                //   z-index: 2;
                //   overflow: hidden;
                //   -webkit-transform: rotate(45deg);
                //   transform: rotate(45deg);
                //   border: 1px dashed;
                //   box-shadow:0 0 0 3px $primary,  0px 21px 5px -18px rgba(0,0,0,0.6);
                //   background: $primary;
                //   text-align: center;
                // }
                .ribbon6 {
                  width: 150px;
                  height:27px;
                  line-height:24px;
                  position: absolute;
                  top: 19px;
                  right: -45px;
                  z-index: 2;
                  overflow: hidden;
                  -webkit-transform: rotate(45deg);
                  transform: rotate(45deg);
                  border: 1px dashed;
                  box-shadow:0 0 0 3px $primary,  0px 21px 5px -18px rgba(0,0,0,0.6);
                  background: $primary;
                  text-align: center;
                  &.public{
                    box-shadow:0 0 0 3px $primary,  0px 21px 5px -18px rgba(0,0,0,0.6);
                    background: $primary;
                  }
                  &.private{
                    box-shadow:0 0 0 3px $secondary,  0px 21px 5px -18px rgba(0,0,0,0.6);
                    background: $secondary;
                  }
                  &.org{
                    box-shadow:0 0 0 3px $warning,  0px 21px 5px -18px rgba(0,0,0,0.6);
                    background: $warning;
                  }
                }
              }
            }
          }
        }
        .tag {
          margin-top: -10px;
          // margin-left: -14px;
          font-size: 13px;
          width: 40%;
          display: block;
          padding: 3px;
          position: absolute;
          left: 0px;
          top: 20px;
            &.public{
              background-color: #48A7F8;
            }
            &.private{
              background-color: #000000;
            }
            &.org{
              background-color: #FFB323;
            }
          color: white;
          // border-top-left-radius: 7px;
          box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.3);
        }
      }
      .btn{
        &.cancel{
          &:hover{
            color: #fff !important;
          }
        }
      }
    }
    }
  }
}




// .mb-progressbar > .mb-progressbar-step.disabled a.mb-progressbar-dot {
//   pointer-events: none;
// }

.cover-container {
  max-width: 42em;  
}


